import {useState} from "react";
import MyProfile from "./MyProfile";
import Billing from "./Billing";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Settings = () => {
	const {user, isAuthenticated, isLoading} = useAuth0()
	const navigate = useNavigate()

	if(!isAuthenticated) {
		navigate("/login")
	}

    const [activeTab, setActiveTab] = useState(1);

    const activeTabClass = 'font-medium border-blue-500 text-gray-700';

    return <div className={'px-8'}>
        <div className={'flex w-full gap-4 text-gray-600 border-b border-gray-200 transition'}>
            <div onClick={() => {setActiveTab(1)}}
                 className={`${activeTab === 1 ? activeTabClass : 'border-white' } border-b-2 py-2 cursor-pointer`}>My Profile</div>
            <div onClick={() => {setActiveTab(2)}}
                 className={`${activeTab === 2 ? activeTabClass : 'border-white' } border-b-2 py-2 cursor-pointer`}>Billing</div>
        </div>
        <div className={'sm:mt-8 mt-4'}>
            {activeTab === 1 && <MyProfile/>}
            {activeTab === 2 && <Billing/>}
        </div>
    </div>
}

export default Settings;
