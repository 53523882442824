import axios from './axios'

export const getUsers = (token, query = "") => {
	console.log('q', query)
	return axios.get('/users', {
		params: {
			token,
			query,
		},
	})
}

export const getUser = (token) => {
	return axios.get('/profile', {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getCompany = (token) => {
	return axios.get('/company-info', {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const saveProfile = (token, profileData, companyData) => {
	return axios.post('/profile/save', {
		profile: profileData,
		company: companyData,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const resetPassword = (token) => {
	return axios.get('/update-pass', {
		params: {
			token,
		}
	})
}

export const checkAuthentication = (token) => {
	return axios.get('/auth0-check', {
		params: {
			token,
		}
	})
}

export const checkVerification = token => {
	return axios.get('/auth0/check-email-verification', {
		params: {
			token,
		}
	})
}

export const resetUserPassword = (token, id) => {
	return axios.get('/user-reset/' + id, {
		params: {
			token,
		}
	})
}

export const deleteUser = (token, id) => {
	return axios.get('/user-delete/' + id, {
		params: {
			token,
		},
	})
}

export const addUser = (token, name, email) => {
	return axios.get('/admin-add-user', {
		params: {
			name,
			email,
			token,
		}
	})
}
