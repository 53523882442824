import {Table} from "antd";
import React from 'react'

const PrintableComponent = React.forwardRef(({invoice, columns}, ref) => {
  return (
    <div ref={ref} style={{ padding: "20px", fontFamily: "Arial" }}>
        <div className={'p-6 bg-white w-[45rem] relative z-40'}>
            <div className={'mt-6 pb-10 flex items-start justify-between border-b border-gray-300'}>
                <img src="/assets/image/blue-logo.png" className={'w-60'} alt="blue-loog"/>
                <div className={'w-[37%] text-xs text-gray-600'}>
                    <p>WOWzer Technologies Inc.</p>
                    <p>Level 11, Godrej BKC Plot C-68, G Block, BKC - Bandra (East) Mumbai 400051 Maharashtra, India</p>
                    <p>Tax number: 27AANFN9351J1ZN </p>
                </div>
            </div>
            <div className={'mt-6 text-sm'}>
                <div className={'text-sm'}>
                    <p className={'font-medium mb-2'}>Billed to</p>
                    <div className={'text-gray-600'}>
                        Company Name: {invoice.company_name}
                        <br />
                        Contact Person: {invoice.contact_name}
                        <br />
                        Address: {invoice.company_address}
                        <br />
                        Tax number: {invoice.company_tax_number}
                    </div>
                </div>
                <div className={'mt-8'}>
                    <p className={'mb-2'}>Invoice No. {invoice.reference_id}</p>
                    <Table className={'invoice-table'} dataSource={[invoice]} columns={columns} pagination={false} />
                    <div className={'flex justify-end px-4 w-full py-4 border-t border-b-2 border-gray-300 mb-6'}>
                        <div>
                            <div className={'flex items-center justify-between  gap-20 mb-4'}>
                                <div>Subtotal</div>
                                <div>$ {invoice.amount_paid - invoice.tax_amount}</div>
                            </div>
                            <div className={'flex items-center justify-between gap-20 mb-4'}>
                                <div>Tax Total</div>
                                <div>$ {invoice.tax_amount}</div>
                            </div>
                            <div className={'flex items-center justify-between gap-20'}>
                                <div>Total</div>
                                <div>$ {invoice.amount_paid}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'text-gray-600'}>
                        <div>Payment Method: Credit Card **** **** **** {invoice.card_last_4}</div>
                        <div className={'my-6'}>
                            Payment Date: {invoice.payment_time}
                            <br />
                            Reference ID: {invoice.reference_id}
                        </div>
                        <div>
                            Place of Supply: Delhi
                            <br />
                            HSN Code: 998439
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
});

export default PrintableComponent;
