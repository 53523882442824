import { Table } from "antd";
import Filter from "../../../shared/Filter";
import Search from "../../../shared/Search";
import {organisationPage} from "../constants";
import {ReactSVG} from "react-svg";
import {useContextState} from "../../../context/ContextProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import { getModuleData } from "../../../utils/organization";

const FolderContentDetails = ({orgId, date, moduleName, logId}) => {
	const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const navigate = useNavigate()

	const [tableHeaders, setTableHeaders] = useState([])
	const [tableRows, setTableRows] = useState([])
	const [tableDataLoading, setTableDataLoading] = useState(false)

	if(!isAuthenticated) {
		navigate("/login")
	}

    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const {setNestedHeader} = useContextState();

	useEffect(() => {
		if(isAuthenticated && !isLoading) {
			(async () => {
				try {
					const token = await getIdTokenClaims()

					setTableDataLoading(true)

					getModuleData(token.__raw, logId, date, moduleName).then(res => {
						console.log('data', res)
						setTableHeaders(portTableHeaders(res.data.headers))
						setTableRows(res.data.content)
					}).catch(err => {
						console.log(err)
					}).finally(() => {
						setTableDataLoading(false)
					})
				} catch(e) {

				}
			})()
		}
	}, [isAuthenticated, isLoading])

	const portTableHeaders = (headers) => {
		let temp = []

		headers.forEach(item => {
			temp.push({
				title: item,
				dataIndex: item.replace(/\s+/g, ''),
				key: item,
			})
		})

		return temp
	}

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Is Reconciled',
            dataIndex: 'isReconciled',
            key: 'isReconciled',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Currency Code',
            dataIndex: 'currencyCode',
            key: 'currencyCode',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Line Amount Type',
            dataIndex: 'lineAmountType',
            key: 'lineAmountType',
        },
        {
            title: 'Subtotal',
            dataIndex: 'subtotal',
            key: 'subtotal',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (_, { total }) => (
                <div className={'flex gap-2'}>
                    <button onClick={() => {total()}}
                        className={'flex gap-2 rounded-lg border border-gray-300 px-2 py-1.5'}>
                        <ReactSVG src={'/assets/icons/eye.svg'} />
                        View
                    </button>
                </div>
            ),
        },
    ];

    return <div className={'px-8'}>
        <div className={'flex items-center justify-between'}>
            <Filter text={'Filter'} />
            <div className={'flex items-center justify-end w-[20%]'}>
                <Search fullWidth={true} placeholder={'Search by module, record or file type...'} />
            </div>
        </div>
        <div style={{ height: '70vh' }} className={'mt-6 overflow-x-auto overflow-y-auto'}>
            <Table dataSource={tableRows} columns={tableHeaders} loading={tableDataLoading} />
        </div>
    </div>
}

export default FolderContentDetails;
