import {Dropdown, Modal, Table} from "antd";
import {usersData} from "./constants";
import Filter from "../../shared/Filter";
import Search from "../../shared/Search";
import AddButton from "../../shared/AddButton";
import {ReactSVG} from "react-svg";
import ScheduleToArchiveModal from "../../modals/ScheduleToArchiveModal";
import {useCallback, useEffect, useState} from "react";
import AddUserModal from "../../modals/AddUserModal";
import UserResetPasswordModal from "../../modals/UserResetPasswordModal"
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {deleteUser, getUsers} from "../../utils/users";
import {debounce} from "lodash";

const Users = () => {
	const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const [users, setUsers] = useState([])
	const [userEditSelected, setUserEditSelected] = useState()
	const [showUserResetPasswordModal, setShowUserResetPasswordModal] = useState()
	const [userResetPasswordSelected, setUserResetPasswordSelected] = useState()
	const [showUserDeleteModal, setShowUserDeleteModal] = useState(false)
	const [userDeleteSelected, setUserDeleteSelected] = useState()
	const [token, setToken] = useState()
	const [fetching, setFetching] = useState(false)
	const [query, setQuery] = useState('')

	const navigate = useNavigate()

	if(!isAuthenticated) {
		navigate("/login")
	}

    const [addUserModalOpen, setAddUserModalOpen] = useState(false);

	useEffect(() => {
		if(!isLoading && isAuthenticated) {
			fetchUsers()
		}
	}, [isLoading, isAuthenticated, query])

	const fetchUsers = async () => {
		try {
			setFetching(true)
			setUsers([])

			const token = await getIdTokenClaims()

			setToken(token.__raw)

			getUsers(token.__raw, query).then(res => {
				if(res.data.success === false) {
					alert(res.data.message)
				} else {
					setUsers(res.data.users)
				}
			}).catch(err => {
				console.error(err)
			}).finally(() => {
				setFetching(false)
			})
		} catch(e) {
			console.error(e)
		}
	}

	const openEditModal = (id) => {
		setUserEditSelected(id)
	}

	const handleDeleteClicked = id => {
		if(window.confirm("Are you sure you want to delete the user?")) {
			setFetching(true)

			deleteUser(token, id).then(res => {
				alert("Successfully deleted the user!");

				fetchUsers()
			}).catch(err => {
				alert("Something went wrong. Please contact support.")
				console.error(err)
			})
		}
	}

	const handleSearchChange = useCallback(
		debounce(text => {
			setQuery(text)
		}, 500), [])

	const handleAddUserModalClose = () => {
		setAddUserModalOpen(false)
	}

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            render: (_, { name }) => (
                <div className={'flex gap-2 items-center text-gray-800'}>
                    {name}
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Date',
            dataIndex: 'created',
            key: 'date',
        },
        {
            title: 'Connected',
            dataIndex: 'connected',
            key: 'connected',
        },
        {
            title: 'Disconnected',
            dataIndex: 'disconnected',
            key: 'disconnected',
        },
        {
            title: 'Not subscribed',
            dataIndex: 'notSubscribed',
            key: 'notSubscribed',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '30%',
            render: (_, { id, role }) => {
                return (
                    <div className={'text-gray-500 flex justify-between items-center'}>
                        <div className={`flex w-max rounded-lg p-1 px-2 gap-2 items-center ${roleHighlight[role]}`}>
                            {role}
                        </div>

                        <Dropdown
                            placement="bottomRight"
                            trigger={['click']}
                            dropdownRender={(menu) => (
                                <div className={'bg-white w-36 p-2 border border-gray-100 rounded-lg shadow-lg'}>
                                    <div className={'font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'} onClick={() => {
										setShowUserResetPasswordModal(true)
										setUserResetPasswordSelected(id)
									}}>
                                        <ReactSVG src={'/assets/icons/edit.svg'} />
										Reset Password
                                    </div>
                                    <div className={'font-medium flex items-center gap-2  text-rose-500 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'} onClick={() => {
											handleDeleteClicked(id)
										}}>
                                        <ReactSVG src={'/assets/icons/delete.svg'} />
                                        Delete
                                    </div>
                                </div>
                            )}
                        >
                            <ReactSVG className={'p-1 cursor-pointer rounded-lg hover:bg-white'}
                                      src={'/assets/icons/dot-menu.svg'} />
                        </Dropdown>
                    </div>
                )
            },
        },
    ];

    const roleHighlight = {
        "Admin": 'text-slate-600 bg-slate-50',
        "Manager": 'text-green-600 bg-green-50',
        "Super Admin": 'text-orange-600 bg-orange-50',
    }

    const mobileRender = users.map((item, index) => {
        return (
            <div key={index} className={'flex justify-between border rounded-lg border-gray-300 text-sm py-3 mt-3 px-4 text-gray-500'}>
                {/*<div>*/}
                {/*    image goes here*/}
                {/*</div>*/}
                <div>
                    <p className={'mb-2 font-medium text-gray-600'}>{item.name}</p>
                    <p className={'mb-2'}>{item.email}</p>
                    <div className={`flex w-max rounded-lg p-1 px-2 gap-2 items-center ${roleHighlight[item.role]}`}>
                        {item.role}
                    </div>
                </div>
                <div className={'flex items-end flex-col justify-between'}>
                    <div>{item.date}</div>
                    <Dropdown
                        placement="bottomRight"
                        trigger={['click']}
                        dropdownRender={(menu) => (
                            <div className={'bg-white w-36 p-2 border border-gray-100 rounded-lg shadow-lg'}>
                                <div className={'font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'}
                                     onClick={() => {
										setShowUserResetPasswordModal(true)
										setUserResetPasswordSelected(item.id)
									 }}>
                                    <ReactSVG src={'/assets/icons/edit.svg'} />
                                    Reset Password
                                </div>
                                <div className={'font-medium flex items-center gap-2  text-rose-500 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'} onClick={() => {
										handleDeleteClicked(item.id)
									}}>
                                    <ReactSVG src={'/assets/icons/delete.svg'} />
                                    Delete
                                </div>
                            </div>
                        )}
                    >
                        <ReactSVG className={'p-1 cursor-pointer rounded-lg hover:bg-white'}
                                  src={'/assets/icons/dot-menu.svg'} />
                    </Dropdown>
                </div>
            </div>
        )
    });

    return <div className={'px-8'}>
		<AddUserModal modalOpen={addUserModalOpen} onClose={handleAddUserModalClose} />

		<UserResetPasswordModal showModal={showUserResetPasswordModal} id={userResetPasswordSelected}
			onClose={() => {
				setShowUserResetPasswordModal(false)
			}} />

        <div className={'flex items-center justify-between'}>
            <Search placeholder={'Search Users...'} handleChange={handleSearchChange} />
            <AddButton text={'Add New User'} clickMethod={() => setAddUserModalOpen(true)} />
        </div>
        <div className={'mt-6 lg:block pb-3 mb-10 hidden'}>
            <Table dataSource={users} columns={columns} loading={fetching} />
        </div>
        <div className={'lg:hidden block'}>
            {mobileRender}
        </div>
    </div>
}

export default Users;
