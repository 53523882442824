import {Table, Button, Dropdown} from "antd";
import {ReactSVG} from "react-svg";
import {archiveData} from "./constants";
import Filter from "../../shared/Filter";
import Search from "../../shared/Search";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {useCallback, useEffect, useState} from "react";
import {getFiles} from "../../utils/archive_files";
import {keyboardImplementationWrapper} from "@testing-library/user-event/dist/keyboard";
import {CheckboxDropdown} from "../AuditLog/CheckboxDropdown";
import {debounce} from "lodash";

const Archive = () => {
	const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const [files, setFiles] = useState()
	const [path, setPath] = useState()
	const [fullPath, setFullPath] = useState()
	const [heirarchy, setHeirarchy] = useState([])

	const navigate = useNavigate()

	if(!isAuthenticated) {
		navigate("/login")
	}

	useEffect(() => {
		if(isAuthenticated && !isLoading) {
			fetchFiles()
		}
	}, [isLoading, isAuthenticated])

	const fetchFiles = async (id = '') => {
		const token = await getIdTokenClaims()
		if(!token)
			return

		getFiles(token.__raw, id).then(res => {
			setFiles(portFileRows(res.data.vault_files))
			setPath(res.data.path)
			setFullPath(res.data.full_path)
		}).catch(err => {
			console.error(err)
		})
	}

	const portFileRows = (files) => {
		let temp = []

		files.forEach((item, key) => {
			temp.push({
				key,
				id: item.id,
				name: item.item_name,
				size: '',
				createdAt: item.created_at,
				type: item.item_type,
			})
		})

		return temp
	}

	const handleFileClick = (id, type) => {
		if(type == "FOLDER") {
			heirarchy.push(id)
			setHeirarchy(heirarchy)

			fetchFiles(id)
		}
	 }

	const handleGoBack = () => {
		heirarchy.pop()
		setHeirarchy(heirarchy)

		fetchFiles(heirarchy[heirarchy.length - 1])
	}

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: window.innerWidth < 600 ? '40%' : '67%',
            render: (_, { id, name, type}) => (
                <div className={`flex gap-2 items-center ${type == "FOLDER" ? 'cursor-pointer' : ''}`} onClick={() => {handleFileClick(id, type)}}>
					{
						type == "FOLDER" ? (
							<ReactSVG src="/assets/icons/folder.svg" />
						) : <ReactSVG src="/assets/icons/file.svg" />
					}
                    {name}
                </div>
            ),
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
    ];

    const rowSelection = {
    };

	const handleSearchChange = () => {}

    return <div className={'lg:px-8 px-3 pb-12'}>
        <div className={'flex items-center justify-between'}>
			<div>
				<Button className={heirarchy.length > 0 ? '' : 'hidden'} onClick={handleGoBack}>
					<ReactSVG src="/assets/icons/arrow-left.svg" />
				</Button>
			</div>
        </div>
        <div className={'mt-6'}>
            <Table dataSource={files} columns={columns} rowSelection={rowSelection} />
        </div>
    </div>
}

export default Archive;
