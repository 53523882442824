import './App.scss';
import Home from "./pages/Home";
import Login from "./pages/Login";
import Auth0Login from "./pages/Auth0Login"
import NotFound from "./pages/NotFound";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import TopNav from "./shared/TopNav";
import SideNav from "./shared/SideNav";
import Organization from "./pages/Organization";
import Archive from "./pages/Archive";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import AuditLog from "./pages/AuditLog";
import {useContextState} from "./context/ContextProvider";
import RouteChangeListener from './utils/route_change_listener';
import store from './store.js'
import {Provider} from 'react-redux';
import {Auth0Provider} from '@auth0/auth0-react';
import getAuth0Config from './utils/auth0.js'
import Feedback from "./shared/Feedback";
import PaymentMethods from "./pages/PaymentMethods";

const App = () => {
	const {collapseSideBar} = useContextState();
	const auth0Config = getAuth0Config()

	return (
		<Auth0Provider domain={auth0Config.domain}
			clientId={auth0Config.clientId}
			authorizationParams={{
				redirect_uri: auth0Config.redirect_url
			}}>
			<Provider store={store}>
				<BrowserRouter>
					<div>
						<SideNav />
						<div className={`${collapseSideBar ? 'sm:ml-20' : 'sm:ml-64'}`}>
							<TopNav />
							<div className={'pt-6'}>
								<RouteChangeListener />
								<Routes>
									<Route path={'/'} element={<Home />} />
									<Route path={'/login'} element={<Login />} />
									<Route path={'/users'} element={<Users />} />
									<Route path={'/archive'} element={<Archive />} />
									<Route path={'/settings'} element={<Settings />} />
									<Route path={'/audit-log'} element={<AuditLog />} />
									<Route path={'/organizations'} element={<Organization />} />
									<Route path={'/payment-methods'} element={<PaymentMethods />} />
									<Route path="*" element={<NotFound />} />
								</Routes>
							</div>
						</div>
					</div>
				</BrowserRouter>
			</Provider>
			<Feedback />
		</Auth0Provider>
	);
}

export default App;
