import axios from './axios'

export const getFiles = (token, id = '') => {
	return axios.get("/vault/" + id, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const addToArchive = (token, id, name, dates) => {
	return axios.post('/vault/queue', {
		id,
		name,
		dates,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}
