import {useState} from "react";
import {Button, Checkbox, Dropdown, Menu} from "antd";
import {ReactSVG} from "react-svg";

export const CheckboxDropdown = ({filterByModules, filterModules}) => {
    const [checkedModules, setCheckedModules] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleCheckboxChange = (value) => {
        setCheckedModules((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );
    };

    const handleVisibleChange = (flag) => {
        setDropdownVisible(flag);
        console.log('flag', flag)
        if(!flag) {
            filterByModules(checkedModules)
        }
    };

    const menu = (
        <Menu>
            {filterModules.map((item) => (
                <Menu.Item key={item.value}>
                    <Checkbox
                        checked={checkedModules.includes(item.value)}
                        onChange={() => handleCheckboxChange(item.value)}
                    >
                        {item.label}
                    </Checkbox>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']} placement="bottom"
                  visible={dropdownVisible} onVisibleChange={handleVisibleChange}
        >
            <div className="flex gap-2 items-center cursor-pointer">
                <Button>
                    <ReactSVG src="/assets/icons/filter.svg" />
                    Select module {checkedModules.length > 0 ? '(' + checkedModules.length + ')' : '' }
                </Button>
            </div>
        </Dropdown>
    );
};
