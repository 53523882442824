import axios from './axios'

export const getPaymentMethods = (token) => {
	return axios.get("/settings/payment-methods", {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const addPaymentMethods = (token, paymentMethodId, makeDefault) => {
	return axios.post("settings/payment-methods-add", {
		payment_method_id: paymentMethodId,
		make_default: makeDefault,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getDefaultMethod = (token) => {
	return axios.get("settings/payment-methods/default-method", {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const markAsDefault = (token, id) => {
	return axios.post("settings/payment-methods-make-default", {
		id,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const updatePaymentMethod = (token, id, formData) => {
	return axios.post("settings/payment-methods-update", {
		id: id,
		name: formData.name,
		email: formData.email,
		phone: formData.phone,
		country: formData.country,
		state: formData.state,
		city: formData.city,
		line1: formData.line1,
		postal_code: formData.postal_code,
		exp_month: formData.exp_month,
		exp_year: formData.exp_year,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const deletePaymentMethod = (token, payment_method_id) => {
	return axios.post("settings/payment-methods-remove", {
		payment_method_id,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}
