import {useEffect, useState} from "react";
import {Modal, Button} from "antd";
import {resetUserPassword} from "../../utils/users";
import {useAuth0} from "@auth0/auth0-react";

const UserResetPasswordModal = ({id, showModal, onClose}) => {
	const {getIdTokenClaims} = useAuth0()
	const [submitting, setSubmitting] = useState(false)

	const handlePasswordReset = () => {
		setSubmitting(true);

		(async() => {
			try {
				const token = await getIdTokenClaims()

				resetUserPassword(token.__raw, id).then(res => {
					if(!res.data.success) {
						console.log("Resetting password failed")
					} else {
						onClose()
					}
					alert(res.data.message)
				}).catch(err => {
					console.error(err)
					alert("Something went wrong. Please contact support")
				}).finally(() => {
					setSubmitting(false)
				})
			} catch(e) {
				console.error(e)
			}
		})()
	}

	const handleClose = () => {
		setSubmitting(false)

		onClose()
	}

    return (
        <Modal
            title={[<div className={'text-2xl'}>Reset Password</div>]}
            centered
            open={showModal}
			destroyOnClose={true}
            footer={[
                <div className={'flex items-center justify-end gap-3'}>
                    <Button key="cancel" className={'px-2 text-sm py-1.5 border border-gray-200 rounded-lg'} onClick={handleClose}>
                        Cancel
                    </Button>

                    <Button type="primary" key="submit" className={'px-2 text-sm py-1.5'}
						onClick={() => {
							handlePasswordReset()
						}} loading={submitting}>
                        Confirm
                    </Button>
                </div>
                ]}
            onCancel={handleClose}
		>
			<div className={'py-8 text-gray-500 text-sm border-b border-gray-300'}>
				<p>
					Are you sure you want to reset password for the user?
				</p>
			</div>
		</Modal>
    )
}

export default UserResetPasswordModal;
