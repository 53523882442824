import { Button, Table } from "antd";
import {organisationFolderContentsData} from "./constants";
import Filter from "../../../shared/Filter";
import Search from "../../../shared/Search";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {organisationPage} from "../constants";
import {ReactSVG} from "react-svg";
import {useContextState} from "../../../context/ContextProvider";
import {useEffect, useState} from "react";
import {downloadFileFromResponse, getFolderModules, moduleFileDownload} from "../../../utils/organization";

const FolderContents = ({orgId, date, onModuleEnter}) => {
	const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const navigate = useNavigate()
	const [contents, setContents] = useState([])
	const [isDownloading, setIsDownloading] = useState(false)

	if(!isAuthenticated) {
		navigate("/login")
	}
    const {setNestedHeader} = useContextState();

	useEffect(() => {
		if(!isLoading && isAuthenticated) {
			(async () => {
				try {
					const token = await getIdTokenClaims()

					getFolderModules(token.__raw, orgId, date).then(res => {
						setContents(res.data.files)
					}).catch(err => {
						console.log('err', err)
					})
				} catch(e) {

				}
			})()
		}
	}, [isLoading, isAuthenticated])

	const downloadFile = link => {
		(async () => {
			setIsDownloading(true)
			try {
				const token = await getIdTokenClaims()

				moduleFileDownload(token.__raw, link).then(res => {
					downloadFileFromResponse(res)
				}).catch(err => {
					console.error(err)
				}).finally(() => {
					setIsDownloading(false)
				})
			} catch(e) {
				setIsDownloading(false)
			}
		})()
	}

    const columns = [
        {
            title: 'Module',
            dataIndex: 'module',
            key: 'module',
            width: '60%',
            render: (key, { id, file }) => {
                return (
                    <div className={'cursor-pointer flex justify-between items-center'}>
                        <div onClick={() => {
							onModuleEnter(id, file)

							setNestedHeader({
								header: file,
								page: organisationPage.ORGANISATION_FOLDER_CONTENTS_DETAILS
							})
						}} className={'flex items-center gap-3 py-1.5'}>
                            <ReactSVG src={'/assets/icons/folder.svg'} />
                            {file}
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Records',
            dataIndex: 'records',
            key: 'records',
			render: (_, {count}) => {
				return (
                <div className={'flex gap-2'}>
					{count}
				</div>
				)
			}
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, val) => (
                <div className={'flex gap-2'}>
                    {
						(() => {
							let buttons = []

							if(val.csv_id) {
								buttons.push(
									<Button className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'} onClick={() => downloadFile(val.csv_link)} loading={isDownloading}>
										CSV
									</Button>
								);
							}
							 if(val.excel_id) {
								buttons.push(
									<Button className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'} onClick={() => downloadFile(val.xls_link)} loading={isDownloading}>
										Excel
									</Button>
								);
							 }
							if(val.json_id) {
								buttons.push(
									<Button className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'} onClick={() => downloadFile(val.json_link)} loading={isDownloading}>
										JSON
									</Button>
								);
							}
							if(val.attach_count && val.attach_count > 0) {
								buttons.push(
									<div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
										Attachments ({val.attach_count})
									</div>
								);
							}

							console.log(buttons)
							return buttons
						})()
                    }
                </div>
            ),
        },
    ];

    let renderRecordsMobile = contents.map((item, key) => {
        return(
            <div className={'p-3 rounded-lg border border-slate-200 mb-2 text-gray-600'}>
                <div className={'flex justify-between mb-3 text-sm'}>
                    <div className={'text-base text-black'}>{item.module}</div>
                    <div className={'mr-1'}>{item.records}</div>
                </div>
                <div className={'flex gap-2 mt-2 flex-wrap'}>
                    {(() => {
                        let buttons = []

                        if(item.csv_id) {
                            buttons.push(
                                <div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
                                    CSV
                                </div>
                            );
                        }
                        if(item.excel_id) {
                            buttons.push(
                                <div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
                                    Excel
                                </div>
                            );
                        }
                        if(item.json_id) {
                            buttons.push(
                                <div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
                                    JSON
                                </div>
                            );
                        }
                        if(item.attach_count && item.attach_count > 0) {
                            buttons.push(
                                <div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
                                    Attachments ({item.attach_count})
                                </div>
                            );
                        }
                        return buttons
                    })()}
                </div>
            </div>
        )
    });

    return <div className={'lg:px-8 px-3'}>
        <div className={'mt-6  lg:block hidden'}>
            <Table dataSource={contents} columns={columns} />
        </div>
        <div className={'sm:hidden block mt-6'}>
            <div className={'flex justify-between mb-3 pb-3 border-b border-slate-200 px-2'}>
                <div className={'flex items-center gap-1 text-xs text-gray-600 cursor-pointer'}>
                    Module
                    <ReactSVG src={'/assets/icons/sort.svg'} />
                </div>
                <div className={'flex items-center gap-1 text-xs text-gray-600 cursor-pointer'}>
                    Records
                    <ReactSVG src={'/assets/icons/sort.svg'} />
                </div>
            </div>
            {renderRecordsMobile}
        </div>
    </div>
}

export default FolderContents;
