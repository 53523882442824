import {ReactSVG} from "react-svg";
import {Link, useLocation} from 'react-router-dom';
import {getPageHeader} from "../../utils/utils";
import {useContextState} from "../../context/ContextProvider";
import {useEffect, useState} from "react";
import {Dropdown} from "antd";
import {notificationData} from "./constant";
import {getAllNotifications, getNotificationCount, getTodayNotifications, readAllNotification} from "../../utils/notifications";
import {useAuth0} from "@auth0/auth0-react";
import {checkAuthentication, checkVerification} from "../../utils/users";

const TopNav = () => {
    const location = useLocation();
    const {nestedHeader, setNestedHeader, collapseSideBar, setCollapseSideBar} = useContextState();
	const {isAuthenticated, getIdTokenClaims, logout, user} = useAuth0()
	const [notificationCount, setNotificationCount] = useState(0)
	const [todayNotifications, setTodayNotifications] = useState([])
	const [allNotifications, setAllNotifications] = useState([])

	useEffect(() => {
		(async() => {
			if(isAuthenticated) {
				try {
					const token = await getIdTokenClaims()

					checkAuthentication(token.__raw).then(res => {
						getNotificationCount(token.__raw).then(res => {
							setNotificationCount(res.data.notificationCount)
						})

						getTodayNotifications(token.__raw).then(res => {
							setTodayNotifications(res.data.notifications)
						})

						getAllNotifications(token.__raw).then(res => {
							setAllNotifications(res.data.notifications)
						})
					}).catch(err => {
						console.error(err)
					})

				} catch(e) {
					console.error(e)
				}
			}
		})()
	}, [getIdTokenClaims, isAuthenticated])

	const [renderNotificationData, setRenderNotificationData] = useState([])

	useEffect(() => {
		if(!allNotifications) return

		 setRenderNotificationData(
			allNotifications.map((item, index) => (
				<div className={'cursor-pointer p-3 mb-2 hover:bg-gray-50 rounded-xl'}>
					<div className={'text-xs mb-1 text-gray-400 flex items-center justify-between'}>
						<div className={'font-medium text-sm text-gray-800 flex items-center gap-2'}>
							{
								item.is_read == 1 ? (
									<div className={'w-2 h-2 rounded-full bg-green-300'} />
								) : (
									<div className={'w-2 h-2 rounded-full bg-blue-300'} />
								)
							}
							{item.type}
						</div>
						<div>{item.created_at}</div>
					</div>
					<div className={'text-xs text-gray-400'}>
						{item.message}
					</div>
				</div>)
			)
		)
	}, [allNotifications])

	const handleMarkAllRead = async () => {
		setNotificationCount(0)
		const token = await getIdTokenClaims()

		readAllNotification(token.__raw).then(res => {
			if(res.data.success) {
				alert(res.data.message)
			} else {
				alert(res.data.error)
			}
		}).catch(err => {
			console.error(err)
		})
	}

	const handleLogout = () => {
		logout({
			returnTo:window.location.origin, 
		})
	}

    return <div className={'flex justify-between items-center border-b border-gray-200 sm:px-8 p-3 w-full sm:py-4 sm:bg-white bg-brand-black'}>
        <div className={'hidden sm:block'}>
            <h2 className={'text-3xl font-medium'}>
                {nestedHeader ? <div className={'flex gap-3 items-center cursor-pointer'} onClick={() => {setNestedHeader(null)}}>
                    <ReactSVG src={'/assets/icons/arrow-left.svg'} />
                    {nestedHeader.header}
                </div> : getPageHeader(location)}
            </h2>
        </div>
        <div className={`w-86 h-8 pl-2 pt-2 pr-2 flex items-center justify-center sm:hidden flex`}>
            <img src={`/assets/logo/logo.png`} className={'w-[120px]'} alt="logo"/>
        </div>
        <div className={'flex items-center'}>

            <div className={'cursor-pointer sm:hidden flex mr-1'}>
                <ReactSVG src="/assets/icons/comment.svg" className={'text-white'} />
            </div>
            <Dropdown
                placement="bottomCenter"
                trigger={['click']}
                dropdownRender={(menu) => (
                    <div className={'bg-white w-[26rem] border border-gray-100 rounded-lg shadow-lg h-[88vh]'}>
                        <div className={'flex items-center justify-between p-3'}>
                            <p className={'font-bold text-2xl'}>Notification</p>
                            <div className={'flex items-center text-blue-600 text-sm gap-2 font-medium cursor-pointer'} onClick={() => {handleMarkAllRead()}}>
                                <ReactSVG src={'/assets/icons/double-checks.svg'} />
                                Mark all as read
                            </div>
                        </div>
                        <div className={'flex items-end w-full bg-gray-100 pt-1 px-3 gap-3 border-t border-gray-200'}>
                            <div className={'px-1 pb-1 font-bold border-b-2 border-blue-400'}>All</div>
                        </div>
                        <div className={'p-1 h-[78vh] overflow-y-auto'}>
                            {renderNotificationData}
                        </div>
                    </div>
                )}
            >
                <div className={'text-white cursor-pointer sm:mr-8 mr-2 sm:hover:bg-gray-100 rounded-lg transition p-3 relative'}>
                    <div className={'sm:flex hidden px-2 text-xs py-[3px] rounded-2xl bg-blue-600 text-white absolute top-0 -right-4'}>
						{notificationCount}
                    </div>
                    <div className={'sm:hidden flex text-xs w-5 h-5 rounded-2xl bg-yellow-400 absolute top-1 right-1 border-[4.5px] border-black'}>
                    </div>
                    <ReactSVG src="/assets/icons/bell.svg" className={'sm:text-black text-white'} />
                </div>
            </Dropdown>

            <Dropdown
                placement="bottomRight"
                trigger={['click']}
                overlayStyle={{
                    minWidth: 'max-content'
                }}
                dropdownRender={(menu) => (
                    <div className={'bg-white w-44 p-2 border border-gray-100 rounded-lg shadow-lg'}>
                        <Link to={'/settings'} className={'flex items-center gap-2 hover:bg-gray-50 px-2 py-2 cursor-pointer'}>
                            <ReactSVG src={'/assets/icons/profile.svg'} />
                            Profile
                        </Link>
                        <div className={'flex items-center gap-2 hover:bg-gray-50 px-2 py-2 cursor-pointer text-rose-500'} onClick={handleLogout}>
                            <ReactSVG src={'/assets/icons/logout.svg'} />
                            Logout
                        </div>
                    </div>
                )}
            >
                <div className={'flex items-center cursor-pointer sm:mr-0 mr-[1.1rem]'}>
                    <div className={'sm:flex hidden w-10 h-10 rounded-full bg-purple-600 text-white justify-center items-center sm:mr-2'}>
                        <img src="/assets/image/user.png" alt="user"/>
                    </div>
                    <div className={'sm:block hidden mr-4'}>
                        <b className={'font-medium mb-1'}>User Name</b>
                        <p className={'text-sm text-gray-500'}>user@wowzer.com</p>
                    </div>
                    <div className={'sm:block hidden cursor-pointer'}>
                        <ReactSVG src="/assets/icons/dropdown.svg" className={'text-black'} />
                    </div>

                    <div className={'cursor-pointer sm:hidden flex'}>
                        <ReactSVG src="/assets/icons/user-place.svg" className={'text-white'} />
                    </div>
                </div>
            </Dropdown>

            <div className={'cursor-pointer sm:hidden flex'} onClick={() => {setCollapseSideBar(!collapseSideBar)}}>
                <ReactSVG src="/assets/icons/burger.svg" className={'text-white'} />
            </div>
        </div>
    </div>
}

export default TopNav;
