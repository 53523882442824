import './feedback.scss';
import {useState} from "react";
import {ReactSVG} from "react-svg";
import {submitFeedback} from '../../utils/feedback';
import {useAuth0} from '@auth0/auth0-react';

const Feedback = () => {
    const [feedbackOpened, setFeedbackOpened] = useState(false);

    const [rating, setRating] = useState(0);
	const [comment, setComment] = useState('')
	const [category, setCategory] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	const {getIdTokenClaims} = useAuth0();

	const onSubmit = async () => {
		try {
			const token = await getIdTokenClaims()

			submitFeedback(token.__raw, rating, category, comment).then(res => {
				if(res.data.is_success) {
					setFeedbackOpened(false)
					setErrorMessage('')

					alert("Feedback submitted")
				} else {
					setErrorMessage(res.data.error)
				}
			}).catch(err => {
				alert("Feedback couldn't be submitted, something went wrong")
				console.error(err)
			})
		} catch(e) {

		}
	}

    return (
        <div>
            <div onClick={() => setFeedbackOpened(!feedbackOpened)}
                className={' rounded-full bg-black text-white hover:bg-blue-600 transition hover:text-white flex gap-3 items-center justify-center px-5 py-2 cursor-pointer z-20 fixed bottom-4 right-4'}>
                <ReactSVG src={'/assets/icons/feedback.svg'} />
                Feedback
            </div>
            <div className={`w-[25rem] shadow-lg fixed transition right-4 z-40 bg-white rounded-2xl border border-gray-300 ${feedbackOpened ? 'feedback-animate-in' : 'feedback-animate-out'}`}>
                <div className={'flex p-4 text-black items-center border-b border-gray-300 justify-between'}>
                    <p className={'text-xl'}>Feedback</p>
                    <ReactSVG
                        onClick={() => setFeedbackOpened(!feedbackOpened)}
                        src={'/assets/icons/cancel.svg'}
                        className={'w-6 text-gray-500 cursor-pointer'} />
                </div>
                <div className={'px-6 py-3 border-b border-gray-300 mb-4'}>
                    <p className={'text-base mb-3'}>How is your first impression of the product?</p>
                    <div className={'flex items-center gap-3 pb-3 border-b border-gray-300'}>
                        {rating > 0 && <ReactSVG onClick={() => {setRating(1)}} src={'/assets/icons/star-filled.svg'}/>}
                        {rating > 1 && <ReactSVG onClick={() => {setRating(2)}} src={'/assets/icons/star-filled.svg'}/>}
                        {rating > 2 && <ReactSVG onClick={() => {setRating(3)}} src={'/assets/icons/star-filled.svg'}/>}
                        {rating > 3 && <ReactSVG onClick={() => {setRating(4)}} src={'/assets/icons/star-filled.svg'}/>}
                        {rating > 4 && <ReactSVG onClick={() => {setRating(5)}} src={'/assets/icons/star-filled.svg'}/>}
                        {rating < 1 && <ReactSVG onClick={() => {setRating(1)}} src={'/assets/icons/star.svg'}/>}
                        {rating < 2 && <ReactSVG onClick={() => {setRating(2)}} src={'/assets/icons/star.svg'}/>}
                        {rating < 3 && <ReactSVG onClick={() => {setRating(3)}} src={'/assets/icons/star.svg'}/>}
                        {rating < 4 && <ReactSVG onClick={() => {setRating(4)}} src={'/assets/icons/star.svg'}/>}
                        {rating < 5 && <ReactSVG onClick={() => {setRating(5)}} src={'/assets/icons/star.svg'}/>}
                    </div>
                    <div className={'mt-3'}>
                        <p className={'text-lg font-light mb-2'}>Select Category</p>
                        <div className={'flex items-start gap-3 mb-4 flex-wrap'} id="feedback_categories">
                            <button className={`outline-none border border-gray-300 rounded-lg px-2 py-1 font-medium ${category == 'backup' ? 'selected-category': ''}`}
								onClick={() => {setCategory('backup')}}>
								Backup
							</button>
                            <button className={`outline-none border border-gray-300 rounded-lg px-2 py-1 font-medium ${category == 'restore' ? 'selected-category': ''}`}
								onClick={() => {setCategory('restore')}}>
								Restore
							</button>
                            <button className={`outline-none border border-gray-300 rounded-lg px-2 py-1 font-medium ${category == 'archived_files' ? 'selected-category': ''}`}
								onClick={() => {setCategory('archived_files')}}>
								Archived Files
							</button>
                            <button className={`outline-none border border-gray-300 rounded-lg px-2 py-1 font-medium ${category == 'ui' ? 'selected-category': ''}`}
								onClick={() => {setCategory('ui')}}>
								UI
							</button>
                            <button className={`outline-none border border-gray-300 rounded-lg px-2 py-1 font-medium ${category == 'others' ? 'selected-category': ''}`}
								onClick={() => {setCategory('others')}}>
								Others
							</button>
                        </div>
                        <textarea className={'w-full outline-none p-3 h-36 border border-gray-300 rounded-xl bg-gray-100'} placeholder={'Tell us on how can we improve...'} onChange={(el) => setComment(el.target.value)}></textarea>
                    </div>
                </div>
                <div className={'px-4'}>
					<div dangerouslySetInnerHTML={{__html: errorMessage}}></div>
                    <button className={'bg-blue-600 text-white rounded-xl flex items-center justify-center py-2 mb-6 w-full'}
						onClick={onSubmit}>
						Submit
					</button>
                </div>
            </div>
        </div>
    )
}

export default Feedback;
