import {Dropdown, Table} from "antd";
import {ReactSVG} from "react-svg";
import {dashboardData, dropdownList} from "./constants";
import {Link, useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {getOrganizations, getOrgDataForDashboard} from "../../utils/organization";
import {useEffect, useState} from "react";
import axios from '../../utils/axios'
import {checkAuthentication} from "../../utils/users";

const Home = () => {
	const navigate = useNavigate();
    const [selectedOrg, setSelectedOrg] = useState(null);
	const [selectedOrgData, setSelectedOrgData] = useState([])
    const [organizations, setOrganizations] = useState([]);
	const {isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const [dropdownOpen, setDropdownOpen] = useState(false)

	useEffect(() => {
		if(!isAuthenticated && !isLoading) {
			navigate("/login")
		}
	}, [isAuthenticated, isLoading])


	useEffect(() => {
		(async () => {
			if (!isLoading && isAuthenticated) {
				try {
					const token = await getIdTokenClaims()

					fetchOrgsList(token.__raw)
				} catch (e) {
					console.error(e)
				}
			}
		})()
	}, [isLoading, isAuthenticated, getIdTokenClaims])

    const columns = [
        {
            title: 'Module',
            dataIndex: 'file',
            key: 'module',
            width: '60%'
        },
        {
            title: 'Records',
            dataIndex: 'count',
            key: 'records',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, val) => (
                <div className={'flex gap-2'}>
                    {
						(() => {
							let buttons = []

							if(val.csv_id) {
								buttons.push(
									<div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
										CSV
									</div>
								);
							}
							 if(val.excel_id) {
								buttons.push(
									<div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
										Excel
									</div>
								);
							 }
							if(val.json_id) {
								buttons.push(
									<div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
										JSON
									</div>
								);
							}
							if(val.attach_count && val.attach_count > 0) {
								buttons.push(
									<div className={'px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300'}>
										Attachments ({val.attach_count})
									</div>
								);
							}

							console.log(buttons)
							return buttons
						})()
}
                </div>
            ),
        },
    ];

	const fetchOrgsList = (token, searchBy = "") => {
		getOrganizations(token, searchBy).then(res => {
			setOrganizations(res.data.accounts)
		}).catch(err => {
			console.error(err)
		})
	}

	const handleOpenChange = (nextOpen, info) => {
		setDropdownOpen(nextOpen)
	}

	const handleDropdownItemClick = (item) => {
		setSelectedOrg(item)
		setDropdownOpen(false);

		(async () => {
			try {
				const token = await getIdTokenClaims()

				refreshTableData(token.__raw, item.id)
			} catch (e) {
				console.error(e)
			}
		})()
	}

	const refreshTableData = (token, orgId) => {
		getOrgDataForDashboard(token, orgId).then(res => {
			setSelectedOrgData(res.data.modules)
		}).catch(err => {
			console.error(err)
		})
	}

	const handleDropdownFilter = (e) => {
		const val = e.target.value;

		(async () => {
			try {
				const token = await getIdTokenClaims()

				fetchOrgsList(token.__raw, val)
			} catch (e) {
				console.error(e)
			}
		})()
	}


	let renderRecordsMobile = selectedOrgData.map((item, key) => {
        return(
            <div className={'p-3 rounded-lg border border-slate-200 mb-2 text-gray-600'}>
                <div className={'flex justify-between mb-3 text-sm'}>
                    <div className={'text-base text-black'}>{item.module}</div>
                    <div className={'mr-1'}>{item.records}</div>
                </div>
                <div className={'flex gap-2 flex-wrap'}>
                    {item.actions.map((action) => {
                        return (
                            <div className={'px-3 text-sm font-medium py-1 rounded-lg border border-gray-200'}>
                                {action.toUpperCase()}
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    });

	const [renderDropdownList, setRenderDropdownList] = useState([])

	useEffect(() => {
		if(!organizations)
			return
	
		if (organizations.length) {
			setRenderDropdownList(
				organizations.map((item, index) => {
					return (
						<div key={index} className={'py-3 px-4 hover:bg-gray-50 cursor-pointer'}
							 onClick={() => {handleDropdownItemClick(item)}}>
							{item.account}
						</div>
					)
				})
			)
		} else {
			setRenderDropdownList(
				[
					(
						<div key={0} className={'py-3 px-4 hover:bg-gray-50 cursor-pointer'}>
							No organization found
						</div>
					)
				]
			)
		}
	}, [organizations])

    return <div className={'sm:px-8 px-4 pb-10'}>
        <div>
            <Dropdown
                placement="bottomCenter"
                trigger={['click']}
				onOpenChange={handleOpenChange}
				open={dropdownOpen}
                dropdownRender={(menu) => (
                    <div className={'bg-white w-full border border-gray-100 rounded-lg sadow-lg'}>
                        <div className={'border-b border-gray-300 p-2'}>
                            <div className={'flex items-center gap-2 rounded-lg hover:bggray-50 p-2'}>
                                <ReactSVG src={'/assets/icons/search.svg'} className={'text-gray-400'} />
                                <input type="text" className={'outline-0 bg-transparent w-full'} placeholder={'Search by title'} onKeyUp={(e) => {handleDropdownFilter(e)}}/>
                            </div>
                        </div>
                        <div className={'mt-2 h-[300px] overflow-y-auto'}>
                            {renderDropdownList}
                        </div>
                    </div>
                )}
            >
                <div className={'sm:w-[30%] w-full cursor-pointer p-2.5 flex justify-between items-center rounded-lg border-gray-300 border text-medium'}>
                    <div className={'font-medium text-sm'}>
                        {selectedOrg ? selectedOrg.account : 'Select Organization'}
                    </div>
                    <ReactSVG src="/assets/icons/common-dropdown.svg" className={'text-gray-700'} />
                </div>
            </Dropdown>
            <div className={'mt-6 lg:block hidden'}>
                <Table dataSource={selectedOrgData} columns={columns} />
            </div>
            <div className={'sm:hidden block mt-6'}>
                <div className={'flex justify-between mb-3 pb-3 border-b border-slate-200 px-2'}>
                    <div className={'flex items-center gap-1 text-xs text-gray-600 cursor-pointer'}>
                        Module
                        <ReactSVG src={'/assets/icons/sort.svg'} />
                    </div>
                    <div className={'flex items-center gap-1 text-xs text-gray-600 cursor-pointer'}>
                        Records
                        <ReactSVG src={'/assets/icons/sort.svg'} />
                    </div>
                </div>
                {renderRecordsMobile}
            </div>
        </div>
    </div>
}

export default Home;
