import {Button, Modal} from "antd";
import {useEffect, useState} from "react";

const SuccessModal = ({title, text, modalOpen, onModalClose}) => {
    const [successModalOpen, setSuccessModalOpen] = useState(false);

	useEffect(() => {
		setSuccessModalOpen(modalOpen)
	}, [modalOpen])

    return (
        <Modal
            title={[<div className={'text-2xl'}>{title}</div>]}
            centered
            open={successModalOpen}
            footer={[
                <div className={'flex items-center justify-end gap-3'}>
                    <Button htmlType="button" type="primary" onClick={() => {setSuccessModalOpen(false); onModalClose();}}>
						Okay
                    </Button>
                </div>
            ]}
            onOk={() => {setSuccessModalOpen(false); onModalClose()}}
            onCancel={() => {setSuccessModalOpen(false); onModalClose()}}
        >
			<div className={'py-8 text-sm border-b border-gray-300 w-full'}>
				<div className={'w-full mb-6'}>
					{text}
				</div>
			</div>
		</Modal>
    )
}

export default SuccessModal;

