import axios from './axios'

export const submitFeedback = (token, rating, category, comment) => {
	return axios.post('/submit-feedback', {
		rating,
		category,
		comment,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}
