import {invoiceData} from "./constant";
import React, {useRef, useEffect}  from 'react'
import {useReactToPrint} from "react-to-print";
import PrintableComponent from "./printable";
import './invoiceModal.scss'
import {Button} from "antd";

const InvoiceModal = ({invoice}) => {
	const printRef = useRef()

	const handlePrint = useReactToPrint({
		content: () => {
			console.log('>>>', printRef.current)
			return printRef.current
		}
	})

    const columns = [
        {
            title: 'Date',
            dataIndex: 'payment_time',
            key: 'date',
        },
        {
            title: 'Description',
            dataIndex: 'service_name',
            key: 'description',
        },
        {
            title: 'Service Period',
            key: 'servicePeriod',
			render: (_, {subs_current_period_start, subs_current_period_end}) => {
				return (
					<div>
						{subs_current_period_start.slice(0, 10)} - {subs_current_period_end.slice(0, 10)}
					</div>
				)
			}
        },
        {
            title: 'Amount',
            key: 'amount',
			render: (_, {amount_paid, tax_amount}) => {
				return (
					<div>
						$ {amount_paid - tax_amount}
					</div>
				)
			},
			width: "15%"
        },
        {
            title: 'Tax',
            key: 'tax',
			render: (_, {tax_amount}) => {
				return (
					<div>
						$ {tax_amount}
					</div>
				)
			},
			width: "15%"
        },
        {
            title: 'Total',
            key: 'total',
			render: (_, {amount_paid}) => {
				return (
					<div>
						$ {amount_paid}
					</div>
				)
			},
			width: "15%"
        },
    ];
	
	const SimpleComponent = React.forwardRef((_, ref) => (
	  <div ref={ref}>
		<h1>Simple Component</h1>
	  </div>
	));

    return (
		<div>
			<SimpleComponent ref={printRef} />
			<PrintableComponent ref={printRef} invoice={invoice} columns={columns} />
			
			<Button onClick={(e) => {
				setTimeout(() => {
					handlePrint(e)
				}, 100)
			}} id="invoice-print-btn">
				Print
			</Button>
		</div>
    )
}

export default InvoiceModal;
