import {useAuth0} from "@auth0/auth0-react";
import {Button, Modal} from "antd";
import {useState} from "react";
import {addUser} from "../../utils/users";

const AddUserModal = ({modalOpen, onClose}) => {
	const {getIdTokenClaims} = useAuth0()
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [adding, setAdding] = useState(false)

	const handleClose = () => {
		onClose()
	}

	const handleAdd = () => {
		setAdding(true);

		 (async () => {
			try {
				const token = await getIdTokenClaims()

				addUser(token.__raw, username, email).then(res => {
					alert(res.data.message)
					if(res.data.success) {
						onClose()
					}
				}).catch(err => {
					console.error(err)
				}).finally(() => {
					setAdding(false)
				})
			} catch(e) {
				console.error(e)
			}
		})()
	}

    return (
        <Modal
            title={[<div className={'text-2xl'}>Add User</div>]}
            centered
            open={modalOpen}
            footer={[
                <div className={'flex items-center justify-end gap-3'}>
                    <Button key="cancel" type="secondary" className={'px-2 text-sm'} onClick={() => handleClose()}>
                        Cancel
                    </Button>

                    <Button key="submit" type="primary" className={'px-2 text-sm'} onClick={() => handleAdd()}>
                        Add
                    </Button>
                </div>
            ]}
            onCancel={() => handleClose()}
			loading={adding}
        >
			<div className={'py-6 text-sm border-b border-gray-300 w-full'}>
				<div className={'w-full mb-6'}>
					<p className={'font-medium text-sm mb-2'}>User Name</p>
					<input className={'w-full bg-gray-100 rounded-lg border-gray-200 border p-2'} type={'text'} placeholder={'Enter user full Name'} onChange={(e) => setUsername(e.target.value)} />
				</div>
				<div className={'w-full mb-2'}>
					<p className={'font-medium text-sm mb-2'}>Email</p>
					<input className={'w-full bg-gray-100 rounded-lg border-gray-200 border p-2'} type={'text'} placeholder={'Enter user email'} onChange={(e) => setEmail(e.target.value)} />
				</div>
			</div>
        </Modal>
    )
}

export default AddUserModal;
