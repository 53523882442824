const DisconnectOrgModal = ({organizationName}) => {
    return (
        <div className={'py-8 text-gray-500 text-sm border-b border-gray-300'}>
            <div>
                Are you sure you want to disconnect from the
                <b className={'ml-2'}>{organizationName}</b> ?
            </div>
            <div className={'mt-6'}>
                This action will revoke access to all associated data and services, and it cannot be undone.
            </div>
        </div>
    )
}

export default DisconnectOrgModal;
