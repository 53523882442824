// ThemeContext.js
import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const ContextProvider = ({ children }) => {
    const [nestedHeader, setNestedHeader] = useState(null);
    const [collapseSideBar, setCollapseSideBar] = useState(false);
    const [openLoginPopup, setOpenLoginPopup] = useState(false);

    return (
        <AppContext.Provider value={{
            nestedHeader,
            openLoginPopup,
            collapseSideBar,
            setNestedHeader,
            setOpenLoginPopup,
            setCollapseSideBar,
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useContextState = () => useContext(AppContext);
