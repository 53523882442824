import {Dropdown, Modal, Table} from "antd";
import {paymentData} from "./constants";
import Search from "../../shared/Search";
import AddButton from "../../shared/AddButton";
import {ReactSVG} from "react-svg";
import {useEffect, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import AddCardModal from "../../modals/AddCardModal";
import UpdateCardModal from "../../modals/UpdateCardModal";
import {deletePaymentMethod, getDefaultMethod, getPaymentMethods, markAsDefault} from "../../utils/payment_methods";
import SuccessModal from "../../modals/SuccessModal/index.jsx";
import dayjs from "dayjs";
import {Stripe} from "stripe";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const PaymentMethods = () => {
	const stripePromise = loadStripe('pk_test_51ON56DHZ3r5Se0To3yARf9k2ljtD8YvPjHZlbxsFmJI9eovhM9bWwlLh3iod55stJEFEGMx13DxVPNUCd6M10QHl00B3a08Se8');

	const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const [users, setUsers] = useState([])
	const [editSelected, setEditSelected] = useState()
	const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false)
	const [defaultMethod, setDefaultMethod] = useState(null)

	const [successTitle, setSuccessTitle] = useState("")
	const [successText, setSuccessText] = useState("")
	const [successModalOpen, setSuccessModalOpen] = useState(false)
	const [successModalCloseHandler, setSuccessModalCloseHandler] = useState(() => {})

	const [cards, setCards] = useState([])
	const [mobileRender, setMobileRender] = useState([])

	const navigate = useNavigate()

	if(!isAuthenticated) {
		navigate("/login")
	}

    const [addCardModalOpen, setAddCardModalOpen] = useState(false);
    const [updateCardModalOpen, setUpdateCardModalOpen] = useState(false);

	useEffect(() => {
		if(!isLoading && isAuthenticated) {
			fetchPaymentMethods()

			fetchDefaultMethod()
		}
	}, [isLoading, isAuthenticated])

	const fetchPaymentMethods = async () => {
		try {
			setPaymentMethodsLoading(true)

			const token = await getIdTokenClaims()

			getPaymentMethods(token.__raw).then(res => {
				if(res.data.success === true) {
					console.log(res.data.cards.data)
					setCards(res.data.cards.data)
				} else {
					console.log(res.data)
				}
			}).catch(err => {
				console.error(err)
			}).finally(() => {
				setPaymentMethodsLoading(false)
			})
		} catch(e) {
			console.error(e)
		}
	}

	const fetchDefaultMethod = async () => {
		try {
			const token = await getIdTokenClaims()

			getDefaultMethod(token.__raw).then(res => {
				if(res.data.success) {
					setDefaultMethod(res.data.default_method)
				}
			}).catch(err => {
				console.error(err)
			})
		} catch(e) {
			console.error(e)
		}
	}

	const onNewPaymentMethodAdd = () => {
		fetchPaymentMethods()
	}

	const makePaymentMethodDefault = async id => {
		try {
			const token = await getIdTokenClaims()

			markAsDefault(token.__raw, id).then(() => {
				setDefaultMethod(id)

				fetchPaymentMethods()
			}).catch(err => {
				alert("Something went wrong while marking the payment method as default")
				console.error(err)
			})
		} catch(e) {
			console.error(e)
		}
	}

	const handleCardUpdate = () => {
		fetchPaymentMethods()
	}

	const handleCardDelete = async (id) => {
		try {
			const token = await getIdTokenClaims()

			setPaymentMethodsLoading(true)

			deletePaymentMethod(token.__raw, id).then(res => {
				if(res.data.success) {
					alert(res.data.message)
					fetchPaymentMethods()
				} else {
					alert(res.data.message)
					console.error(res.data)
				}
			}).catch(err => {
				console.error(err)
			}).finally(() => {
				setPaymentMethodsLoading(false)
			})
		} catch(e) {
			console.error(e)
		}
	}

    const columns = [
        {
            title: 'Name on card',
            dataIndex: 'name',
            key: 'name',
            render: (_, { billing_details }) => (
                <div className={'flex gap-2 items-center text-gray-800'}>
                    {billing_details.name}
                </div>
            ),
        },
        {
            title: 'Card number',
            dataIndex: 'number',
            key: 'number',
            render: (_, {card}) => (
                <div className={'flex gap-3 items-center text-gray-800'}>
                    **** **** {card.last4}
                    <img className={'h-5 w-9'} src={"/assets/image/" + card.display_brand + '.png'} alt={card.display_branch}/>
                </div>
            )
        },
        {
            title: 'Email',
            key: 'email',
			dataIndex: 'email',
			render: (_, {billing_details}) => (
                <div className={'flex gap-2 items-center text-gray-800'}>
                    {billing_details.email}
                </div>
			)
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiry',
            key: 'expiry',
			render: (_, {card}) => (
                <div className={'flex gap-2 items-center text-gray-800'}>
                    {card.exp_month}/{card.exp_year}
                </div>
			)
        },
        {
            title: 'Entry Date',
            dataIndex: 'entry',
            key: 'entry',
			render: (_, {created_at}) => (
                <div className={'flex gap-2 items-center text-gray-800'}>
                    {dayjs(created_at).format("DD-MM-YYYY")}
                </div>
			)
        },
        {
            title: 'Action',
            dataIndex: 'default',
            key: 'default',
            render: (_, payment_method) => {
                return (
                    <div className={'flex items-center justify-between gap-4'}>
                        <div>
                            {payment_method.id == defaultMethod ?
                                <div className={'text-blue-400 flex items-center gap-2'}>
                                    <ReactSVG src={'/assets/icons/green-check.svg'} />
                                    Default
                                </div> :
                                <div className={'px-3 cursor-pointer font-medium py-1 mr-2 rounded-lg border border-gray-300'}
									onClick={() => {
										setPaymentMethodsLoading(true)
										makePaymentMethodDefault(payment_method.id)
									}}>
                                    Mark Default
                                </div>}
                        </div>
                        <Dropdown
                            placement="bottomRight"
                            trigger={['click']}
                            dropdownRender={() => (
                                <div className={'bg-white w-36 p-2 border border-gray-100 rounded-lg shadow-lg'}>
                                    <div className={'font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'}
                                         onClick={() => {
											 setEditSelected(payment_method)
											 setUpdateCardModalOpen(true)
										 }}>
                                        <ReactSVG src={'/assets/icons/modify.svg'} />
                                        Edit
                                    </div>
                                    <div className={'font-medium flex items-center gap-2  text-rose-500 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'} onClick={() => handleCardDelete(payment_method.id)}>
                                        <ReactSVG src={'/assets/icons/delete.svg'} />
                                        Delete
                                    </div>
                                </div>
                            )}
                        >
                            <ReactSVG className={'p-1 cursor-pointer rounded-lg hover:bg-white'}
                                      src={'/assets/icons/dot-menu.svg'} />
                        </Dropdown>
                    </div>
                )
            },
        },
    ];

	useEffect(() => {
		if(cards === undefined)
			return

		const render = cards.map((item, index) => {
			return (
				<div key={index} className={'flex justify-between border-t border-gray-300 text-sm py-3 mt-3 px-4 text-gray-500'}>
					<div>
						<div className={'mb-3 text-gray-500 font-medium'}>{item.name}</div>
						<div className={'flex gap-3 mb-1 items-center text-gray-800'}>
							{item.number}
							<img className={'h-5 w-9'} src={"/assets/image/" + item.type + '.png'} alt={item.type}/>
						</div>
						<div>{item.email}</div>
					</div>
					<div className={'flex flex-col items-end'}>
						<div className={'flex gap-2 mb-3'}>
							{item.default && <div className={'text-blue-400 flex items-center gap-2'}>
								<ReactSVG src={'/assets/icons/green-check.svg'}/>
								Default
							</div>}

							<Dropdown
								placement="bottomRight"
								trigger={['click']}
								dropdownRender={() => (
									<div className={'bg-white w-36 p-2 border border-gray-100 rounded-lg shadow-lg'}>
										<div className={'font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'}>
											<ReactSVG src={'/assets/icons/green-check.svg'}/>
											Make Default
										</div>
										<div className={'font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'}
											 onClick={() => setUpdateCardModalOpen(true)}>
											<ReactSVG src={'/assets/icons/modify.svg'} />
											Edit
										</div>
										<div className={'font-medium flex items-center gap-2  text-rose-500 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer'}>
											<ReactSVG src={'/assets/icons/delete.svg'} />
											Delete
										</div>
									</div>
								)}
							>
								<ReactSVG className={'p-1 cursor-pointer rounded-lg hover:bg-white'}
										  src={'/assets/icons/dot-menu.svg'} />
							</Dropdown>
						</div>
						<div className={'flex gap-3 items-center text-gray-800'}>
							<span className={'text-gray-400'}>Exp:</span> {item.expiry}
						</div>
						<div className={'flex gap-3 items-center text-gray-800'}>
							<span className={'text-gray-400'}>Entry:</span> {item.entry}
						</div>
					</div>
				</div>
			)
		})

		setMobileRender(render)
	}, [cards])

    return <div className={'lg:px-8 px-2'}>
		<Elements stripe={stripePromise}>
			<AddCardModal modalOpen={addCardModalOpen} onCreate={onNewPaymentMethodAdd} />
		</Elements>

        <Modal
            title={[<div className={'text-2xl'}>Updated Card Information</div>]}
            centered
            open={updateCardModalOpen}
            footer={null}
            onOk={() => setUpdateCardModalOpen(false)}
            onCancel={() => setUpdateCardModalOpen(false)}
			destroyOnClose={true}
			zIndex={300}
        >
            <UpdateCardModal paymentMethod={editSelected} onUpdate={handleCardUpdate} />
        </Modal>

        <div className={'flex items-center justify-end'}>
            <AddButton text={'Add New'} clickMethod={() => setAddCardModalOpen(true)} />
        </div>
        <div className={'mt-6 mb-10 pb-5 lg:block hidden'}>
            <Table dataSource={cards} columns={columns} loading={paymentMethodsLoading} />
        </div>
        <div className={'lg:hidden block'}>
            {mobileRender}
        </div>

		<SuccessModal title={successTitle} text={successText} modalOpen={successModalOpen} onModalClose={successModalCloseHandler} />
    </div>
}

export default PaymentMethods;
