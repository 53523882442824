import {ReactSVG} from "react-svg";

const AddButton = ({text, clickMethod}) => {
    return (
        <div onClick={clickMethod}
            className={'flex gap-2 rounded-lg items-center px-2.5 font-bold py-1.5 border border-gray-300 cursor-pointer text-sm text-gray-700'}>
            <ReactSVG src="/assets/icons/plus.svg" />
            <p className={'w-max'}>{text}</p>
        </div>
    )
}

export default AddButton;
