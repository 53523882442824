import {Modal, Table} from "antd";
import {useEffect, useState} from "react";
import {DatePicker} from 'antd'
import dayjs from "dayjs";
import {addToArchive} from "../../utils/archive_files";
import {useAuth0} from "@auth0/auth0-react";

const AddToArchiveModal = ({orgId, organizationName, backupDates, modalOpen, onModalClose}) => {
	const {getIdTokenClaims} = useAuth0()
	const [orgName, setOrgName] = useState('')
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
	const [token, setToken] = useState('')
	const [selectedDates, setSelectedDates] = useState('')

	useEffect(() => {
		(async() => {
			try {
				const token = await getIdTokenClaims() 

				setToken(token.__raw)
			} catch(e) {
				console.error(e)
			}
		})()
	}, [getIdTokenClaims])

	useEffect(() => {
		setArchiveModalOpen(modalOpen)
	}, [modalOpen])

	useEffect(() => {
		setOrgName(organizationName)
	}, [organizationName])

	const handleOrgNameChange = e => {
		setOrgName(e.target.value)
	}

	const [allowedDates, setAllowedDates] = useState([])

	useEffect(() => {
		if(backupDates) {
			let temp = []

			backupDates.forEach(item => {
				temp.push(dayjs(item.rec_date))
			})

			setAllowedDates(temp)
		}
	}, [backupDates])

	const disableDate = (currentDate) => {
		allowedDates.forEach(allowedDate => {
			console.log(allowedDate.isSame(currentDate, 'day'))
		})
		return !allowedDates.some(date => date.isSame(currentDate, 'day'))
	}

	const submit = () => {
		if(token) {
			addToArchive(token, orgId, orgName, selectedDates)
		} else {
			console.log("Token not found")
		}
	}

    return (
        <Modal
            title={[<div className={'text-2xl'}>Add to Archive</div>]}
            centered
            open={archiveModalOpen}
            footer={[
                <div className={'flex items-center justify-end gap-3'}>
                    <div key="cancel" className={'px-2 text-sm py-1.5 border border-gray-200 rounded-lg'} onClick={() => {setArchiveModalOpen(false); onModalClose()}}>
                        Cancel
                    </div>

                    <div key="submit" className={'px-2 text-sm py-1.5 bg-blue-600 text-white rounded-lg'} onClick={() => {setArchiveModalOpen(false); onModalClose(); submit()}}>
                        Add to Archive
                    </div>
                </div>
            ]}
            onOk={() => {setArchiveModalOpen(false); onModalClose(); submit()}}
            onCancel={() => {setArchiveModalOpen(false); onModalClose()}}
        >
			<div className={'py-8 text-sm border-b border-gray-300 w-full'}>
				<div className={'w-full mb-6'}>
					<p className={'font-medium text-sm mb-2'}>Name</p>
					<input className={'w-full bg-gray-100 rounded-lg border-gray-200 border p-2'} type={'text'} placeholder={'Name'} value={orgName} onChange={handleOrgNameChange} />
				</div>
				<div className={'w-full'}>
					<p className={'font-medium text-sm mb-2'}>Archive Date</p>
					<DatePicker style={{width: '100%'}} disabledDate={disableDate}
						onChange={(_, dates) => setSelectedDates(dates)}
						multiple={true}
					/>
				</div>
			</div>
		</Modal>
    )
}

export default AddToArchiveModal;
