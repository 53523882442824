export const getPageHeader = (location) => {

	const headers = {
		'/organizations': 'Xero Organizations',
		'/audit-log': 'Audit Log',
		'/archive': 'Archived Files',
		'/users': 'Users',
		'/settings': 'Settings',
		'/': 'Dashboard',
		'/payment-methods': "Payment Methods"
	};

	// Default header if no specific URL matches
	return headers[location.pathname] ?? 'Dashboard';
}
