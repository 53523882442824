import './billing.scss';
import {Table} from "antd";
import {ReactSVG} from "react-svg";
import {billingData} from "./constants";
import InvoiceModal from "../../../modals/InvoiceModal";
import {useEffect, useState} from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getBillingData } from '../../../utils/billing';

const Billing = () => {
	const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const navigate = useNavigate()

    const [openedIndex, setOpenedIndex] = useState(0)
	const [invoices, setInvoices] = useState([])
	const [selectedInvoice, setSelectedInvoice] = useState({})

	useEffect(() => {
		if(!isAuthenticated) {
			navigate("/login")
		}
	}, [isAuthenticated])

	useEffect(() => {
		fetchBillingInvoices()
	}, [getIdTokenClaims])

	const fetchBillingInvoices = async () => {
		try {
			const token = await getIdTokenClaims()

			getBillingData(token.__raw).then(res => {
				if(res.data.success) {
					setInvoices(res.data.data)
				} else {
					alert("Something went wrong. Please contact support");
				}
			}).catch(err => {
				console.log(err)
			})
		} catch(e) {
			console.log(e)
		}
	}

    const [openInvoicePopup, setOpenInvoicePopup] = useState(false);

    const columns = [
        {
            title: 'Organization Name',
            dataIndex: 'org_name',
            key: 'name',
            width: '30%',
        },
        {
            title: 'Billing date',
            dataIndex: 'payment_time',
            key: 'date',
        },
        {
            title: 'Amount',
            dataIndex: 'amount_paid',
            key: 'amount',
			render: (_, {amount_paid}) => {
				return (
					<div>
						${amount_paid}
					</div>
				)
			}
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, invoice) => {
                return (
                    <div onClick={() => {setOpenInvoicePopup(true); setSelectedInvoice(invoice)}} className={'cursor-pointer text-gray-700 w-max px-2 py-1.5 gap-1 flex rounded-xl border border-gray-300 font-medium items-center'}>
                        <ReactSVG src="/assets/icons/invoice.svg" />
                        Invoice
                    </div>
                )
            },
        },
    ];

    const mobileRender = invoices.map((item, index) => {
        return (
            <div key={index} className={' border rounded-lg border-gray-300 text-sm py-3 mt-3 px-4 text-gray-500'} onClick={() => {setOpenedIndex(index)}}>
                <div className={'flex items-center justify-between cursor-pointer'}>
                    <div className={'flex items-center gap-2'}>
                        <ReactSVG src="/assets/icons/common-dropdown.svg"
                                  className={`text-gray-700 w-6 h-6 flex items-center justify-center bg-gray-100 rounded-full ${openedIndex === index && 'rotate-180'}`} />
                        <div className={' text-gray-500 font-medium'}>{item.org_name}</div>
                    </div>
                    <div className={'text-gray-500 flex gap-2 items-center'}>
                        <div className={`h-2 w-2 rounded-full bg-red-300`} />
                        {item.amount_paid}
                    </div>
                </div>
                {openedIndex === index && <div className={'mt-2'}>
                    <div className="flex justify-between mb-1">
                        <div>Plan type:</div>
                        <div>{item.type}</div>
                    </div>
                    <div className="flex justify-between mb-1">
                        <div>Date:</div>
                        <div>{item.payment_time.substring(0, 20)}...</div>
                    </div>
                    <div onClick={() => {setOpenInvoicePopup(true); setSelectedInvoice(item.invoice)}}
                         className={'cursor-pointer text-gray-700 w-max px-1.5 py-1 gap-1 flex rounded-lg border text-xs border-gray-300 font-medium items-center'}>
                        <ReactSVG src="/assets/icons/invoice.svg" />
                        Invoice
                    </div>

                </div>}
            </div>
        )
    });

    return <div>
        {openInvoicePopup && <div className={'fixed z-50 flex items-center justify-center top-0 left-0 w-full h-full bg-gray-400 bg-opacity-50'}>
            <div className={'w-full absolute z-10 h-full'} onClick={() => {setOpenInvoicePopup(false)}}/>
            <InvoiceModal invoice={selectedInvoice}/>
        </div>}
        <div className={'mt-6 billing lg:block hidden'}>
            <Table dataSource={invoices} pagination={false} columns={columns} />
        </div>
        <div className={'lg:hidden block'}>
            {mobileRender}
        </div>
    </div>
}

export default Billing;
