import {useContextState} from "../../context/ContextProvider";
import {organisationPage} from "./constants";
import Accounts from "./Accounts";
import Folder from "./Folders";
import FolderContents from "./FolderContents";
import FolderContentDetails from "./FolderContentDetails";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {useState} from "react";

const Organization = () => {
	const {user, isAuthenticated, isLoading} = useAuth0()
	const navigate = useNavigate()
	const [selectedOrgId, setSelectedOrgId] = useState()
	const [selectedFolderDate, setSelectedFolderDate] = useState()
	const [moduleName, setModuleName] = useState()
	const [logId, setLogId] = useState()

	if(!isAuthenticated) {
		navigate("/login")
	}

	const handleOrgSelection = (orgId) => {
		setSelectedOrgId(orgId)
	}

	const handleFolderDateSelection = (date) => {
		setSelectedFolderDate(date)
	}

	const handleModuleSelection = (logId, moduleName) => {
		setModuleName(moduleName)
		setLogId(logId)
	}

    const {nestedHeader} = useContextState();

    if (nestedHeader && nestedHeader.page === organisationPage.ORGANISATION_FOLDER && selectedOrgId) {
        return <Folder orgId={selectedOrgId} onFolderEnter={handleFolderDateSelection} />
    } else if (nestedHeader && nestedHeader.page === organisationPage.ORGANISATION_FOLDER_CONTENTS && selectedOrgId) {
        return <FolderContents orgId={selectedOrgId} date={selectedFolderDate} onModuleEnter={handleModuleSelection} />
    } else if (nestedHeader && nestedHeader.page === organisationPage.ORGANISATION_FOLDER_CONTENTS_DETAILS && selectedOrgId) {
        return <FolderContentDetails orgId={selectedOrgId} date={selectedFolderDate} moduleName={moduleName} logId={logId} />
    } else {
        return <Accounts onOrgIdChange={handleOrgSelection} />
    }
}

export default Organization;
