import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
import {useContextState} from "../../context/ContextProvider";
import {useEffect} from "react";

const SideNav = () => {

    useEffect(() => {
        if (window.innerWidth < 500) {
            setCollapseSideBar(true);
        }
    }, []);

    const {collapseSideBar, setCollapseSideBar} = useContextState();

    const navWidth = collapseSideBar ? '-left-64 w-20' : 'left-0 sm:w-64 w-56';

    return <div className={`${navWidth} bg-brand-black h-[100vh] fixed top-0 sm:left-0 flex flex-col justify-between z-50`}>
        <button onClick={() => {setCollapseSideBar(!collapseSideBar)}}
            className={'absolute hidden sm:flex w-10 h-10 rounded-full shadow-lg items-center justify-center -right-5 top-14 bg-white -bottom-5 z-30'}>
            <ReactSVG src={'/assets/icons/side-toggle.svg'} />
        </button>
        <div>
            <div className={`${collapseSideBar ? 'w-[2.5rem] mx-auto pt-6 flex justify-center' : 'sm:w-60 w-[12.74rem] sm:pl-4 pl-5 sm:pt-6 pt-[1.14rem] pr-16'} `}>
                <img src={`/assets/logo/${collapseSideBar ? 'short-logo.png' : 'logo.png'}`} className={'w-100'} alt="logo"/>
            </div>
            <div className={'p-4 mt-10'}>
                <NavLink to={'/'}
                         className={(({isActive}) => (`flex mb-1 group items-center gap-3 px-3 p-2 ${isActive ? 'bg-blue-500 text-white' : ''} rounded-lg hover:bg-blue-500 hover:text-white text-gray-400 cursor-pointer`))}>
                    <ReactSVG src="/assets/icons/dashboard.svg" />
                    {!collapseSideBar && 'Dashboard'}
                </NavLink>
                <NavLink to={'/organizations'}
                         className={(({isActive}) => (`flex mb-1 group items-center gap-3 px-3 p-2 ${isActive ? 'bg-blue-500 text-white' : ''} rounded-lg hover:bg-blue-500 hover:text-white text-gray-400 cursor-pointer`))}>
                    <ReactSVG src="/assets/icons/organization.svg" />
                    {!collapseSideBar && 'Xero Organization'}
                </NavLink>
                <NavLink to={'/audit-log'}
                    className={(({isActive}) => (`flex mb-1 group items-center gap-3 px-3 p-2 ${isActive ? 'bg-blue-500 text-white' : ''} rounded-lg hover:bg-blue-500 hover:text-white text-gray-400 cursor-pointer`))}>
                    <ReactSVG src="/assets/icons/audit.svg" />
                    {!collapseSideBar && 'Audit Log'}
                </NavLink>
                <NavLink to={'/archive'}
                    className={(({isActive}) => (`flex mb-1 group items-center gap-3 px-3 p-2 ${isActive ? 'bg-blue-500 text-white' : ''} rounded-lg hover:bg-blue-500 hover:text-white text-gray-400 cursor-pointer`))}>
                    <ReactSVG src="/assets/icons/archived.svg" />
                    {!collapseSideBar && 'Archived Files'}
                </NavLink>
                <NavLink to={'/payment-methods'}
                         className={(({isActive}) => (`flex mb-1 group items-center gap-3 px-3 p-2 ${isActive ? 'bg-blue-500 text-white' : ''} rounded-lg hover:bg-blue-500 hover:text-white text-gray-400 cursor-pointer`))}>
                    <ReactSVG src="/assets/icons/payment.svg" />
                    {!collapseSideBar && 'Payment Methods'}
                </NavLink>
                <NavLink to={'/users'}
                         className={(({isActive}) => (`flex mb-1 group items-center gap-3 px-3 p-2 ${isActive ? 'bg-blue-500 text-white' : ''} rounded-lg hover:bg-blue-500 hover:text-white text-gray-400 cursor-pointer`))}>
                    <ReactSVG src="/assets/icons/user.svg" />
                    {!collapseSideBar && 'Users'}
                </NavLink>
                <div className={'px-6 my-5 border-t border-gray-700 w-100'} />
                <NavLink to={'/settings'}
                         className={(({isActive}) => (`flex mb-1 group items-center gap-3 px-3 p-2 ${isActive ? 'bg-blue-500 text-white' : ''} rounded-lg hover:bg-blue-500 hover:text-white text-gray-400 cursor-pointer`))}>
                    <ReactSVG src="/assets/icons/settings.svg" />
                    {!collapseSideBar && 'Settings'}
                </NavLink>
            </div>
        </div>
        {!collapseSideBar && <div className={'text-center text-gray-400 p-4 text-xs'}>
            Copyright © 2020-2024 All rights reserved.
        </div>}
    </div>
}

export default SideNav;
