import axios from './axios'

export const getNotificationCount = (token) => {
	return axios.get("notifications-count", {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getTodayNotifications = (token) => {
	return axios.get("today-notifications", {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getAllNotifications = (token) => {
	return axios.get("notifications", {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const readAllNotification = (token) => {
	return axios.post('notifications/read-all', {}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}
