import axios from './axios'

export const addOrg = (token) => {
	window.location.href = axios.defaults.baseURL + "/add-account?API-TOKEN=" + token;
	// return axios.get("/add-account", {
	// 	headers: { "API-TOKEN": token,
	// 	}
	// })
}

export const deleteOrg = (token, id) => {
	return axios.post('/xero-delete', {
		asd: 'asd',
		id,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getOrgDataForDashboard = (token, orgId) => {
	return axios.get('/dashboard/org-backup-data/' + orgId, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getOrg = (token, id) => {
	return axios.get('/organization/' + id, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getOrganizations = (token, searchBy) => {
	return axios.get("/xero", {
		params: {
			q: searchBy,
			token,
		}
	})
}

export const getFolders = (token, orgId) => {
	return axios.get("/xero/" + orgId, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getFolderModules = (token, orgId, folder_date) => {
	return axios.get("/xero/" + orgId + "/" + folder_date, {

		headers: {
			"API-TOKEN": token,
		}
	})
}

export const getModuleData = (token, orgId, folder_date, module) => {
	return axios.get("/xero/" + orgId + "/" + folder_date + "/" + module, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const subUpdate = (token, orgId) => {
	return axios.post("/xero/update-subscription", {
		id: orgId,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const connectXero = (token, orgId) => {
	return axios.post("/connect/xero", {
		id: orgId,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const disconnectXero = (token, orgId) => {
	return axios.post("/xero-disconnect", {
		id: orgId,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const xeroClearData = (token, id) => {
	return axios.post("xero-clear", {
		id,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const restoreOrg = (token, source, dest, folder_date) => {
	return axios.post("/xero-resync", {
		source,
		dest,
		folder_date,
	}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const moduleFileDownload = (token, link) => {
	return axios.post("/" + link, {}, {
		responseType: 'blob',
		headers: {
			"API-TOKEN": token,
		},
	})
}

export const downloadFileFromResponse = response => {
	// Extract the Content-Disposition header
	const contentDisposition = response.headers['content-disposition'];

	// Initialize a default filename
	let fileName = 'downloaded_file';

	// Extract the filename if Content-Disposition contains it
	if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
		const matches = contentDisposition.match(/filename="?(.+)"?/);
		console.log('matches', matches)
		if (matches && matches[1]) {
			fileName = matches[1];
		}
	}
	fileName = fileName.replace(/^\"+|\"+$/g, '')

	// Create a Blob from the response
	const blob = new Blob([response.data], {type: response.headers['content-type']});

	// Create a temporary URL for the Blob
	const url = window.URL.createObjectURL(blob);

	// Create an anchor element to trigger the download
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = url;
	a.download = fileName; // Use the extracted filename

	// Append the anchor to the body
	document.body.appendChild(a);
	a.click();

	// Cleanup
	window.URL.revokeObjectURL(url);
	document.body.removeChild(a);
}
