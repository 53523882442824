import {useAuth0} from "@auth0/auth0-react";
import {Dropdown, DatePicker, Modal} from "antd";
import {useEffect, useState} from "react";
import {ReactSVG} from "react-svg";
import {restoreOrg} from "../../utils/organization";

const ErrorMessageComponent = ({message}) => {
	if(message) {
		return <div className={'pt-2'}>
			<p className={'text-red-500 font-bold'}>{message}</p>
		</div>
	}
}

const AddRestoreOrgOrgModal = ({organizationId, organizationName, organizations, backupDates, onModalClose, onRestoreSubmit}) => {
	const {getIdTokenClaims} = useAuth0()

	const [choosenItem, setChoosenItem] = useState('')
	const [dropdownVisible, setDropdownVisible] = useState(false)
	const [restoreOrgModalOpen, setRestoreOrgModalOpen] = useState(false)
	const [choosenBackupDate, setChoosenBackupDate] = useState('')
	const [restoreId, setRestoreId] = useState()
	const [formError, setFormError] = useState()

	useEffect(() => {
		if(organizationName) {
			setRestoreOrgModalOpen(true)
			setFormError('')
		}
	}, [organizationName])

	const setDisabledDates = (value) => {
		const date = value.format('YYYY-MM-DD')
		for(let i = 0; i < backupDates.length; i++) {
			return !(date == backupDates[i].rec_date)
		}
		return true
	}

	const renderDropdownList = organizations.map((item, index) => (
		<div key={index} className={'py-3 px-4 hover:bg-gray-50 cursor-pointer'} onClick={() => {
			setChoosenItem(item.account)
			setRestoreId(item.id)
			setDropdownVisible(false)
		}
		}>
			{item.account}
		</div>
	))

	const handleFormSubmit = async () => {
		try {
			const token = await getIdTokenClaims()

			if(choosenBackupDate && choosenBackupDate.format("YYYY-MM-DD") && organizationId && restoreId) {
				const date = choosenBackupDate.format("YYYY-MM-DD")

				restoreOrg(token.__raw, organizationId, restoreId, date).then(res => {
					if(res.data.success) {
						setRestoreOrgModalOpen(false)
						onModalClose()
						onRestoreSubmit()
					} else {
						alert(res.data.message)
					}
				}).catch(err => {
					console.error(err)
					alert(err.response.data)
				})
			} else {
				setFormError("All fields are required")
			}
		} catch(e) {
			console.error(e)
		}
	}

    return (
		<Modal
            title={[<div className={'text-2xl'}>Restore organization</div>]}
            centered
            open={restoreOrgModalOpen}
            footer={[
                <div className={'flex items-center justify-end gap-3'}>
                    <div key="cancel" className={'px-2 text-sm py-1.5 border border-gray-200 rounded-lg cursor-pointer'} onClick={() => setRestoreOrgModalOpen(false)}>
                        Cancel
                    </div>

                    <div key="submit" className={'px-2 text-sm py-1.5 bg-blue-500 text-white rounded-lg cursor-pointer'} onClick={handleFormSubmit}>
                        Confirm
                    </div>
                </div>
                ]}
            onOk={() => {setRestoreOrgModalOpen(false); onModalClose()}}
            onCancel={() => {setRestoreOrgModalOpen(false); onModalClose()}}
        >
			<ErrorMessageComponent message={formError} />

			<div className={'py-8 text-gray-500 text-sm border-b border-gray-300'}>
				<div className={'text-sm text-gray-700 mb-3'}>
					<p>Backup Date</p>
					<DatePicker disabledDate={setDisabledDates} onChange={setChoosenBackupDate} className={'w-full py-2'}>
					</DatePicker>
				</div>

				<div className={'text-sm text-gray-700 mb-3'}>
					<p>Sync Data From</p>
					<input type="text" className={'rounded-lg px-3 py-2 border border-gray-300 mt-1 w-full'}
						value={organizationName} disabled="true"/>
				</div>


				<div className={'text-sm text-gray-700 mb-3'}>
					<p>Sync Data To</p>
					<Dropdown
						onClick={() => setDropdownVisible(true)}
						placement="bottomCenter"
						trigger={['click']}
						visible={dropdownVisible}
						className={'w-full'}
						dropdownRender={(menu) => (
							<div className={'bg-white w-full border border-gray-100 rounded-lg shadow-lg'}>
								<div className={'border-b border-gray-300 p-2'}>
									<div className={'flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2'}>
										<ReactSVG src={'/assets/icons/search.svg'} className={'text-gray-400'} />
										<input type="text" className={'outline-0 bg-transparent w-full'} placeholder={'Search by title or description...'}/>
									</div>
								</div>
								<div className={'mt-2'}>
									{renderDropdownList}
								</div>
							</div>
						)}
						>
						<div className={'w-[30%] cursor-pointer p-2.5 flex justify-between items-center rounded-lg border-gray-300 border text-medium'}>
							<div className={'font-medium text-sm'}>
								{choosenItem}
							</div>
							<ReactSVG src="/assets/icons/common-dropdown.svg" className={'text-gray-700'} />
						</div>
					</Dropdown>
				</div>
			</div>
		</Modal>
    )
}

export default AddRestoreOrgOrgModal;
