import {ReactSVG} from "react-svg";

const Search = ({placeholder, handleChange, fullWidth = false}) => {
	const handleSearchChange = val => {
		handleChange(val)
	}

    return (
        <div className={`flex gap-1 rounded-lg items-center text-sm text-gray-400 min-w-[20%] ${fullWidth && 'w-full'}`}>
            <ReactSVG src="/assets/icons/search.svg" />
            <input type="text" placeholder={placeholder}
				className={'border-none p-1 w-full font-light outline-none ring-0'}
				onChange={e => handleSearchChange(e.target.value)}
		/>
        </div>
    )
}

export default Search;
