import {ReactSVG} from "react-svg";
import "./style.css"

const Filter = ({text, filters = false}) => {
    return (
        <div className={'flex items-center'}>
            <div className={'flex gap-2 rounded-lg items-center px-2 font-bold py-1.5 border border-gray-300 cursor-pointer text-sm text-gray-700'}>
                <ReactSVG src="/assets/icons/filter.svg" />
                {text}
            </div>
            {
                filters && <>

                    <div className={'flex gap-2 rounded-lg items-center px-2 py-1.5 mx-3 bg-gray-100 cursor-pointer text-sm text-gray-500'}>
                        Currency
                        <ReactSVG src="/assets/icons/cancel.svg" />
                    </div>
                    <div className={'flex gap-2 rounded-lg items-center px-2 py-1.5 bg-gray-100 cursor-pointer text-sm text-gray-500'}>
                        7 July, 2024 - 10 Jul, 2024
                        <ReactSVG src="/assets/icons/cancel.svg" />
                    </div>

                    <p className={'cursor-pointer text-sm ml-4'}>Clear all filters</p>
                </>
            }
        </div>
    )
}

export default Filter;
