import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: ""
    },
    reducers: {
        check(state, action) {
            if (!state.token) {
                return
            }
            return true
        }
    }
})

export const { check } = authSlice.actions

export default authSlice.reducer
