import {Modal, Table} from "antd";
import {ReactSVG} from "react-svg";
import {folderData} from "./constants";
import Search from "../../../shared/Search";
import Filter from "../../../shared/Filter";
import {useEffect, useState} from "react";
import {useContextState} from "../../../context/ContextProvider";
import {organisationPage} from "../constants";
import DisconnectOrgModal from "../../../modals/DisconnectOrgModal";
import AddToArchiveModal from "../../../modals/AddToArchiveModal";
import ScheduleToArchiveModal from "../../../modals/ScheduleToArchiveModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {getFolders, getOrg} from "../../../utils/organization";

const Folder = ({orgId, onFolderEnter}) => {
	const {user, isAuthenticated, isLoading} = useAuth0()
	const navigate = useNavigate()
	const {getIdTokenClaims} = useAuth0()
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);

	if(!isAuthenticated) {
		navigate("/login")
	}

    const {setNestedHeader} = useContextState();
    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
	const [backupDates, setBackupDates] = useState([])
	const [orgName, setOrgName] = useState('')

	useEffect(() => {
		if(!isLoading && isAuthenticated) {
			(async () => {
				try {
					const token = await getIdTokenClaims()

					getFolders(token.__raw, orgId).then(res => {
						 setBackupDates(res.data.dates)
					}).catch(err => {
						console.log('err', err)
					})

					getOrg(token.__raw, orgId).then(res => {
						if(res.data.success) {
							setOrgName(res.data.org.account)
						}
					}).catch(err => {
						console.error(err)
					})
				} catch(e) {

				}
			})()
		}
	}, [isLoading, isAuthenticated])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (key, {rec_date}) => {
                if (rec_date) {
                    return (
                        <div className={'cursor-pointer flex justify-between items-center'}>
                            <div onClick={() => {
								onFolderEnter(rec_date)
								setNestedHeader({
									header: rec_date,
									page: organisationPage.ORGANISATION_FOLDER_CONTENTS
								})
							}} className={'flex items-center gap-3 py-1.5'}>
                                <ReactSVG src={'/assets/icons/folder.svg'} />
                                {rec_date}
                            </div>
                            <div>
                                {hoveredRow === key ? (
                                    <div className={' flex items-center gap-4'}>
                                        <div className={'px-2'}>
                                            <button onClick={() => setArchiveModalOpen(true)}
                                                className={'text-xs flex items-center gap-2 px-4 py-1.5 text-gray-800 bg-white border border-gray-300 rounded-lg font-bold'}>
                                                <ReactSVG src={'/assets/icons/archive.svg'} />
                                                Add to Archive
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    )
				}
            },
        }
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    return <div className={'lg:px-8 px-3'}>
		<AddToArchiveModal orgId={orgId} organizationName={orgName} backupDates={backupDates} modalOpen={archiveModalOpen}
			onModalClose={() => {setArchiveModalOpen(false)}}
		/>

        <Modal
            title={[<div className={'text-2xl'}>Archive Schedule</div>]}
            centered
            open={scheduleModalOpen}
            footer={[
                <div className={'flex items-center justify-end gap-3'}>
                    <div key="cancel" className={'px-2 text-sm py-1.5 border border-gray-200 rounded-lg'} onClick={() => setScheduleModalOpen(false)}>
                        Cancel
                    </div>

                    <div key="submit" className={'px-2 text-sm py-1.5 bg-blue-600 text-white rounded-lg'} onClick={() => setScheduleModalOpen(false)}>
                        Schedule to Archive
                    </div>
                </div>
            ]}
            onOk={() => setScheduleModalOpen(false)}
            onCancel={() => setScheduleModalOpen(false)}
        >
            <ScheduleToArchiveModal organizationName={'WOWzer Technologies (BC) Inc'} />
        </Modal>

        <div className={'lg:flex items-center justify-between'}>
            {selectedRowKeys.length ?
                <div className={'flex items-center gap-3'}>
                    <button className={'text-xs flex items-center gap-2 lg:px-4 px-2 py-1 lg:py-1.5 text-gray-800 bg-white border border-gray-300 rounded-lg font-bold'}>
                        <ReactSVG src={'/assets/icons/archive.svg'} />
                        Add to Archive
                    </button>
                    <button className={'text-xs flex items-center gap-2 lg:px-4 px-2 py-1 lg:py-1.5 text-gray-800 bg-white border border-gray-300 rounded-lg font-bold'}>
                        <ReactSVG src={'/assets/icons/schedule.svg'} />
                        Schedule to Archive
                    </button>
                </div> : (<div/>) }
            <div className={'flex items-center lg:justify-end lg:w-[20%] w-full lg:mt-0 mt-2'}>
                <Search fullWidth={window.innerWidth < 600}
                    placeholder={'Search by module, record or file type...'} />
            </div>
        </div>

        <div className={'mt-6'}>
            <Table
                onRow={(record) => ({
                    onMouseEnter: () => setHoveredRow(record.key),
                    onMouseLeave: () => setHoveredRow(null),
                })}
                dataSource={backupDates}
                rowSelection={rowSelection}
                columns={columns} />
        </div>
    </div>
}

export default Folder;
