import './scheduleToArchiveModal.scss';
import {DatePicker} from "antd";

const ScheduleToArchiveModal = ({organizationName}) => {
    return (
        <div className={'py-8 text-sm border-b border-gray-300 w-full'}>
            <div className={'w-full mb-6'}>
                <p className={'font-medium text-sm mb-2'}>Name</p>
                <input className={'w-full bg-gray-100 rounded-lg border-gray-200 border p-2'} type={'text'} placeholder={'Name'} value={organizationName} />
            </div>
            <div className={'w-full'}>
                <p className={'font-medium text-sm mb-2'}>Choose schedule date</p>
                <DatePicker popupStyle={{minWidth: 'max-content'}}
                    popupClassName={'min-w-max w-max p-0'}
                    rootClassName={'scheduleArchive w-full bg-gray-100 p-2 rounded-lg border-gray-200 border'} onChange={() => {}} />
            </div>
        </div>
    )
}

export default ScheduleToArchiveModal;
