import {useEffect, useState} from "react";
import {Modal} from "antd";
import {ReactSVG} from "react-svg";
import {deleteOrg} from "../../utils/organization";
import {useAuth0} from "@auth0/auth0-react";

const DeleteOrgModal = ({orgId, organizationName, modalStatus, onModalClose, onSuccess}) => {
	let [deleteOrgModalOpen, setDeleteOrgModalOpen] = useState()
	let {getIdTokenClaims} = useAuth0()

	const handleDeleteOrg = () => {
		(async() => {
			try {
				let token = await getIdTokenClaims()

			deleteOrg(token.__raw, Number(orgId)).then(res => {
				onSuccess()
				setDeleteOrgModalOpen(false)
			}).catch(err => {
				console.error('err', err)
			})
			} catch(e) {

			}
		})()
	}

	useEffect(() => {
		if(modalStatus) {
			setDeleteOrgModalOpen(true)
		}
	}, [modalStatus])

    return (
		<Modal
            title={[<div className={'text-2xl'}>Delete Organization</div>]}
            centered
            open={deleteOrgModalOpen}
            footer={[
                <div className={'flex items-center justify-end gap-3'}>
                    <div key="cancel" className={'px-2 text-sm py-1.5 border border-gray-200 rounded-lg cursor-pointer'} onClick={() => {setDeleteOrgModalOpen(false); onModalClose()}}>
                        Cancel
                    </div>

                    <div key="submit" className={'px-2 text-sm py-1.5 bg-blue-500 text-white rounded-lg cursor-pointer'} onClick={handleDeleteOrg}>
                        Confirm
                    </div>
                </div>
                ]}
            onOk={() => {setDeleteOrgModalOpen(false); onModalClose()}}
            onCancel={() => {setDeleteOrgModalOpen(false); onModalClose()}}
        >
			<div className={'py-8 text-gray-500 text-sm border-b border-gray-300'}>
				<div>
					Are you sure you want to delete
					<b className={'ml-2'}>{organizationName}</b>?
				</div>
				<div className={'mt-6'}>
					This action will delete all the data, and it cannot be undone.
				</div>
			</div>
		</Modal>
    )
}

export default DeleteOrgModal;

