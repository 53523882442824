import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {check} from '../features/auth/auth.js'
import {useSelector, useDispatch} from 'react-redux'

function RouteChangeListener() {
	const authToken = useSelector((state) => state.authReducer.token)

	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		let newRouteUri = location.pathname

		console.log(newRouteUri)
		// if (!authToken && newRouteUri != "/login") {
		// 	redirectToLogin(navigate)
		// }
	}, [location])

	return null
}

function redirectToLogin(navigate) {
	navigate("/login")
}

export default RouteChangeListener;
